import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
					<path d="M4285 7210 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
					<path d="M4184 7146 c-72 -49 -179 -160 -235 -245 -198 -303 -196 -707 5
-1010 53 -80 165 -195 232 -240 40 -26 48 -28 61 -16 8 8 11 20 7 26 -3 6 0
15 7 21 12 7 11 9 -1 7 -8 -1 -28 -1 -45 1 -26 1 -27 3 -10 9 11 5 23 7 28 4
4 -2 7 5 7 16 0 12 -3 21 -7 21 -17 1 -104 89 -152 153 -127 169 -179 343
-168 555 7 126 24 196 76 307 59 125 132 218 243 307 48 38 54 47 38 51 -11 3
-18 1 -15 -3 8 -13 -8 -18 -35 -9 -21 6 -22 7 -5 8 14 1 21 8 22 22 2 17 -1
19 -10 10 -6 -6 -16 -11 -21 -11 -6 1 0 10 13 21 40 33 18 30 -35 -5z m43 -72
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1 -1351 c-6 -6 -36 9
-36 18 0 5 9 4 20 -3 10 -7 18 -14 16 -15z m4 -68 c7 -8 8 -15 2 -15 -5 0 -15
7 -22 15 -7 8 -8 15 -2 15 5 0 15 -7 22 -15z"/>
					<path d="M5952 6756 c-84 -28 -116 -78 -110 -172 5 -73 25 -108 81 -137 53
-27 170 -34 227 -12 80 29 80 29 80 6 0 -17 6 -21 30 -21 l30 0 0 95 0 95
-111 0 -110 0 3 -27 c3 -25 8 -29 48 -33 50 -6 44 -17 -10 -20 -113 -6 -140 7
-140 68 0 26 6 41 20 50 26 16 120 18 148 3 27 -14 152 -15 152 0 0 27 -38 71
-78 91 -56 27 -197 34 -260 14z"/>
					<path d="M8145 6761 c-93 -24 -136 -83 -104 -143 17 -31 75 -50 188 -59 57 -4
108 -12 113 -17 33 -33 -117 -51 -162 -19 -27 19 -134 24 -145 7 -11 -18 24
-67 62 -86 31 -15 60 -19 153 -19 66 1 131 6 152 14 47 16 78 54 78 96 0 60
-47 82 -199 95 -57 5 -108 13 -113 18 -32 32 121 48 167 18 27 -18 145 -23
145 -6 0 25 -27 63 -58 82 -29 18 -53 22 -141 24 -58 2 -119 0 -136 -5z"/>
					<path d="M6360 6595 l0 -165 190 0 190 0 0 40 0 40 -131 0 c-130 0 -130 0
-127 23 3 21 7 22 131 25 l127 3 0 34 0 34 -127 3 c-120 3 -128 4 -128 23 0
19 8 20 128 23 l127 3 0 39 0 40 -190 0 -190 0 0 -165z"/>
					<path d="M6820 6595 l0 -165 60 0 60 0 0 89 c0 50 4 92 9 95 4 3 46 -37 92
-89 l83 -95 73 0 73 0 0 165 0 165 -65 0 -65 0 0 -80 c0 -44 -3 -80 -7 -80 -5
1 -37 37 -72 80 l-65 80 -88 0 -88 0 0 -165z"/>
					<path d="M7350 6595 l0 -165 65 0 65 0 0 165 0 165 -65 0 -65 0 0 -165z" />
					<path d="M7540 6644 c0 -119 6 -144 44 -176 60 -51 237 -63 320 -22 70 34 81
59 84 195 l4 119 -66 0 -66 0 0 -98 c0 -122 -7 -132 -98 -132 -92 0 -102 14
-102 135 l0 95 -60 0 -60 0 0 -116z"/>
					<path d="M4255 6700 c-4 -6 -14 -8 -24 -5 -39 12 -143 -134 -157 -223 l-7 -42
36 0 c34 0 37 2 43 38 8 44 27 85 62 131 20 27 22 35 11 42 -8 5 -9 9 -3 9 5
0 15 -5 22 -12 9 -9 12 -9 12 0 0 6 12 18 28 26 15 9 21 15 12 16 -9 0 -21 -5
-28 -12 -6 -6 -15 -9 -18 -5 -4 4 4 15 17 26 13 11 19 20 12 21 -6 0 -15 -5
-18 -10z m-30 -30 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6
0 7 -4 4 -10z"/>
					<path d="M4269 6423 c-1 -10 -1 -22 0 -26 1 -4 -4 -18 -10 -30 -9 -17 -9 -19
1 -11 15 15 31 84 19 84 -5 0 -10 -8 -10 -17z"/>
					<path d="M7690 6374 c-57 -11 -91 -27 -110 -51 -25 -33 -26 -84 -1 -107 27
-24 105 -46 165 -46 83 0 127 -10 124 -29 -5 -26 -88 -35 -140 -14 -24 9 -69
17 -101 19 -69 3 -80 -10 -47 -55 27 -39 99 -61 196 -61 139 0 214 31 230 96
17 69 -52 108 -209 117 -49 2 -93 9 -98 14 -34 34 116 52 165 20 28 -19 131
-24 141 -7 11 17 -28 69 -65 86 -35 17 -195 28 -250 18z"/>
					<path d="M5840 6206 l0 -166 171 0 c156 0 175 2 205 21 98 60 104 215 10 282
-29 20 -44 22 -208 25 l-178 3 0 -165z m310 44 c15 -24 12 -72 -7 -92 -13 -14
-31 -18 -95 -18 l-78 0 0 58 c0 32 3 62 8 66 14 14 162 2 172 -14z"/>
					<path d="M6417 6308 c-69 -125 -137 -255 -137 -261 0 -4 31 -7 68 -7 64 0 70
2 80 25 11 24 15 25 102 25 87 0 90 -1 96 -25 6 -24 10 -25 81 -25 l75 0 -58
108 c-31 59 -71 133 -88 165 l-30 57 -77 0 -77 0 -35 -62z m136 -83 c9 -20 17
-38 17 -40 0 -3 -20 -5 -45 -5 l-45 0 20 40 c11 22 24 40 28 40 5 0 16 -16 25
-35z"/>
					<path d="M6700 6320 l0 -50 75 0 75 0 0 -115 0 -115 65 0 64 0 3 113 3 112 78
3 77 3 0 49 0 50 -220 0 -220 0 0 -50z"/>
					<path d="M7204 6318 c-14 -29 -53 -101 -85 -159 -33 -58 -59 -109 -59 -112 0
-4 32 -7 70 -7 65 0 71 2 82 25 11 24 15 25 100 25 85 0 89 -1 98 -25 9 -23
14 -25 80 -25 38 0 70 3 70 8 0 4 -19 41 -42 82 -22 41 -60 111 -84 155 l-43
80 -80 3 -80 3 -27 -53z m129 -93 c9 -20 17 -38 17 -40 0 -3 -18 -5 -40 -5
-22 0 -40 2 -40 5 0 11 34 75 40 75 3 0 14 -16 23 -35z"/>
					<path d="M8040 6256 c0 -101 2 -117 23 -147 31 -47 83 -70 175 -76 99 -7 175
13 217 57 30 31 30 33 33 156 l4 124 -66 0 -66 0 0 -105 c0 -97 -2 -105 -22
-116 -30 -16 -112 -16 -143 1 -24 13 -25 16 -25 117 l0 103 -65 0 -65 0 0
-114z"/>
					<path d="M8557 6364 c-4 -4 -7 -79 -7 -166 l0 -158 191 0 c192 0 219 4 246 39
21 28 15 79 -12 107 -24 24 -24 27 -10 54 21 41 19 64 -9 97 l-24 28 -184 3
c-101 2 -187 0 -191 -4z m298 -94 c0 -12 -18 -16 -87 -18 -83 -3 -88 -2 -88
18 0 20 5 21 88 18 69 -2 87 -6 87 -18z m23 -132 c3 -16 -7 -18 -97 -18 -95 0
-101 1 -101 20 0 20 5 21 97 18 84 -3 98 -6 101 -20z"/>
					<path d="M4291 6324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M4261 6309 c0 -8 6 -24 14 -35 14 -18 14 -18 15 4 0 13 -4 21 -9 17
-5 -3 -12 3 -14 12 -4 14 -5 14 -6 2z"/>
					<path d="M4296 6242 c-2 -4 2 -16 10 -27 8 -10 14 -14 14 -8 0 7 -5 15 -10 18
-6 4 -8 11 -5 16 4 5 4 9 1 9 -3 0 -7 -3 -10 -8z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
